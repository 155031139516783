/* Icon */

	.icon {
		@include icon;
		border-bottom: none;
		position: relative;

		> .label {
			padding: 3px;
		}

		&.alt {
			&:before {
				background-color: _palette(fg-bold);
				border-radius: 100%;
				color: _palette(bg);
				display: inline-block;
				height: 2em;
				line-height: 2em;
				text-align: center;
				width: 2em;
			}
		}
	}

	a.icon {
		&.alt {
			&:before {
				@include vendor('transition', 'background-color #{_duration(transition)} ease-in-out');
			}

			&:hover {
				&:before {
					background-color: _palette(accent1);
				}
			}

			&:active {
				&:before {
					background-color: desaturate(darken(_palette(accent1), 15), 5);
				}
			}
		}
	}