@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,600,600italic');
@import url('https://fonts.googleapis.com/css?family=Arimo');


// Breakpoints.

	@include breakpoints((
		xlarge:    (1281px,   1680px   ),
		large:     (981px,    1280px   ),
		medium:    (737px,    980px    ),
		small:     (481px,    736px    ),
		xsmall:    (361px,    480px    ),
		xxsmall:   (null,     360px    )
	));

// Mixins.

	@mixin inner {
		> .inner {
			@include padding(4em, 0);
			margin: 0 auto;
			max-width: _size(inner);
			width: calc(100% - 6em);

			@include breakpoint('<=small') {
				@include padding(3em, 0);
				// width: calc(100% - 3em);
			}
		}
	}

// Base.

	@import 'base/reset';
	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/row';
	@import 'components/section';
	@import 'components/form';
	@import 'components/box';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/actions';
	@import 'components/icons';
	@import 'components/pagination';
	@import 'components/table';
	@import 'components/button';
	@import 'components/tiles';
	@import 'components/contact-method';
	@import 'components/spotlights';

// Layout.

	@import 'layout/header';
	@import 'layout/banner';
	@import 'layout/main';
	@import 'layout/contact';
	@import 'layout/footer';
	@import 'layout/wrapper';
	@import 'layout/menu';


#list-inline{
	text-align: center;
	li{
		display: inline-block;
		margin: auto 20px;
	}	
}
.link-to-site{
	text-align: center;
	margin: 20px auto;
}
	
#footer{
	.copyright{
		li{
			color: #ffffff;
		}	
	}
}

#banner {
	.inner{
		margin: 0 auto;
	}
}  

.alert {
	position: relative;
	margin-top: 15px;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-danger {
    color: #721c24;
    border-color: #f5c6cb;
}

.alert-success {
    color: #155724;
    border-color: #c3e6cb;
}

#success {
	text-align: center;
	width: 100%;
}

#feedbackMessage{
	strong{
		color: #ffffff;
		font-weight: 900;
	}
}
#NotFound{
	#message404{
		font-size: 90px;
		font-weight: 900;
	}
	position: flex;
	margin: 0 auto;
	top: 200px;
	right: 50px;
	left: 50px;
	font-size: 40px;
	text-align: center;
}

.back-top {
    font-size: 60px;
	position: fixed;
	z-index: 3;
    bottom: 25px;
    right: 30px;
    color: #ffffff;
    cursor: pointer;
}

.header-details{
	margin: 20px;
}

@media only screen and (max-width: 600px ){
	#header{
		a{
			span{
				display: none;
			}
		}
	}
	#github-links{
		display: block;
		li{
			margin: 1rem 0;
			a{
				width: 237px;
			}
		}
	}

}

@media only screen and (max-width: 927px) {
	#banner{
		.inner{
			text-align: center;
			margin-bottom: .5rem;

			.img-profile{
				margin: 0;
			}

			.header-details{
				.content{
					.actions{
						li{
							display: block;
							width: 100%;
							a{
								text-align: center;

							}
						}

					}
				}
			}
		}
	}

}


#portfolio{
	background-color: #242943;
	
	.portfolio-img{
		display: block;
		position: relative;
		max-width: 100%; 
		max-height: 300px; 
		margin: 0 auto;
	}

	.inner{
		header{
			.major{
				text-align: center;
				width: 100%;
			}
		}
	}

	.start-break{
		color: #ffffff;
		border: .1rem solid #ffffff;
		margin: 10px auto;
	}

	.list-inline-item{
		span{
			font-weight: 800;
		}
	}
}

@media screen and (max-width: 480px){
	#portfolio{
		.portfolio-link{
			.caption {
				.caption-content{
					height: 200px;
					i{
						margin-top: 80px;
					}
				}
			}
		}
	}
	#contact{
		.inner{
			.actions{
				display: block !important;
				li{
					margin-top: 10px;
				}
			}
		}
	}
}

.alert-danger {
    color: #721c24;
    border-color: red;
    background-color: red;
}

#about{
	.inner{
		font-weight: 400;
		p{
			font-size: 22px;
		}
		.techList{
			font-size: 20px;
			li{
				list-style-type: circle;
			}
		}
	}
}

#contact{
	.inner{
		margin: 0 auto;
	}
}

#footer{
	.inner{
		border-radius: 10px;
		padding: 60px !important;	
	}
}
.portfolio-container{
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.portfolio-item{
	border: 1px solid #ffffff;
	border-radius: 10px;
	padding: 20px;
	margin: 10px;
	-webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
	box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
	display: flex;
	flex-direction: column;
}

.portfolio-description{
	font-size: 22px;
	margin-top: 5px;
}