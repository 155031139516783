/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;

		li {
			display: inline-block;
			margin: 1px;
			padding: 0 1em 0 0;

			&:last-child {
				padding-right: 0;
			}
		}

		@include breakpoint('<=small') {
			li {
				padding: 0 0.75em 0 0;
				width: 30%;

			}
		}
	}